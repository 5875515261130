.dropdown {
  /* float: left; */
  overflow: hidden;
}

.dropdown .dropbtn {
  font-size: 17px;
  border: none;
  outline: none;
  color: white;
  padding: 14px 0px;
  background-color: inherit;
  font-family: system-ui;
  margin: 0;
  font-weight: 500;
}
@media screen and (max-width:615px) {
  .dropdown .dropbtn {
  padding: 0px 0px;

  }
  
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 190px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content h5 {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  font-size: 16px;
}

.topnav a:hover,
.dropdown:hover .dropbtn {
  /* background-color: #555; */
  color: white;
}

.dropdown-content h5:hover {
  background-color: #ddd;
  color: black;
  cursor: pointer;
}

.dropdown:hover .dropdown-content {
  display: block;
}

/* @media screen and (max-width: 600px) {
  .topnav a:not(:first-child),
  .dropdown .dropbtn {
    display: none;
  }
  .topnav a.icon {
    float: right;
    display: block;
  }
}

@media screen and (max-width: 600px) {
  .topnav.responsive {
    position: relative;
  }
  .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
  .topnav.responsive .dropdown {
    float: none;
  }
  .topnav.responsive .dropdown-content {
    position: relative;
  }
  .topnav.responsive .dropdown .dropbtn {
    display: block;
    width: 100%;
    text-align: left;
  }
} */
