* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0 !important;
  padding-right: initial !important;
  /* overflow: auto !important; */
}

.dropbtn {
  background: transparent;
  /* padding: 16px; */
  padding: 8px;
  border: none;
  line-height: 24px;
  color: #009035;
  cursor: pointer;
  font-size: 17px;
  font-family: system-ui;
  font-weight: 500;
  position: relative;
}

.dropbtn::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 0;
  left: 0;
  right: 0;
  height: 2px;
  transition: 0.1s ease-in-out;
}
.dropbtn::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
  height: 3px;
  /* background-color: #f4e757; */
  transition: 0.3s ease-in-out;
}

.dropbtn1 {
  background: transparent;
  /* padding: 16px; */
  padding: 8px;
  border: none;
  line-height: 24px;
  color: #fff;
  cursor: pointer;
  font-size: 17px;
  font-family: system-ui;
  font-weight: 500;
  position: relative;
}

/* .dropbtn::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 0;
  left: 0;
  right: 0;
  height: 2px;
  transition: 0.1s ease-in-out;
}
.dropbtn::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
  height: 3px;
  background-color: #f4e757;
  transition: 0.3s ease-in-out;
} */

.cardScroll {
}

.NotFoundBtn {
  background-color: #ccc !important;
}

.rmdp-wrapper {
  width: auto !important;
}

.rmdp-top-class {
  display: flow !important;
}

.rmdp-arrow-container {
  display: none !important;
}

.sidebarscrollbar {
}

.commonClass {
  /* height: 300px; */
  width: 100%;
  /* text-align: center; */
}

.commonClass h3 {
  font-size: 18px;
  padding: 10px 0px 14px 0px;
  display: flex;
  align-items: center;
  color: #4f3409;
}

@media (max-width: 767px) {
  .commonClass h3 {
    display: block;
    font-size: 15px;
  }
}

/* #e4a70a78 */

.icon {
  font-size: 20px !important;
}

/* main Scrollbar */

/* Change the main scrollbar track color */
::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
  height: 3px;
}

/* Change the scrollbar track */
::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px; /* Color of the track */
}

/* Change the scrollbar handle */
::-webkit-scrollbar-thumb {
  background: #c8c0c0;
  border-radius: 10px; /* Color of the handle */
}

/* Change the scrollbar handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #fff;
  border-radius: 10px; /* Color of the handle on hover */
}

.tableDatanotFound {
  width: 100%;
  /* text-align: center; */
}

.tableDatanotFound h6 {
  font-size: 18px;
}

.allBooxShadowdiv {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px;
  width: 100%;
  padding: 10px 5px;
  background-color: #e4a70a78;
}

.CommonGreterThenIcon {
  color: #4f3409;
  font-size: 24px;
}

.PathTitles span {
  cursor: pointer;
  color: #4f3409;
}

.PathTitles span:hover {
  text-decoration: underline;
}

.swal-button--view {
  background-color: #4caf50; /* Green */
  color: white;
}
.swal-button--confirm {
  background-color: #008cba; /* Blue */
  color: white;
}

/* visiting card details */

.mainVisitingCard {
  text-align: center;
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  border-radius: 10px;
}

.mainVisitingCard h3 {
  font-size: 30px;
  margin-bottom: 15px;
  font-family: system-ui;
}

.contentdiv {
  display: flex;
  gap: 5px;
  align-items: center;
  padding-top: 5px;
}

.contentdivPOP {
  width: 170px;
  text-align: left;
}

.contentdivPOP h6 {
  font-size: 14px;
  font-family: system-ui;
}

.mainVisitingCards {
  /* justify-content: space-between;
  padding: 10px; */
}

.imgboxdivPOP {
  width: 200px;
  height: 70px;
  margin-top: 10px;
}

.imgboxdivPOP img {
  width: 100%;
  height: 100%;
  box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset,
    rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
  border-radius: 5px;
  border: 1px solid #dcdcdc;
}

.signdiv {
  margin-top: 7px;
  display: flex;
}

@media (max-width: 425px) {
  .swal-text {
    font-size: 12px;
  }

  .swal-title {
    font-size: 18px;
    padding: inherit;
  }
}

.viewer-container {
  position: relative;
}

.image-title {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
}

.captacha {
  position: relative !important;
}

/* #canv {
  border: 1.5px dashed rgba(0, 0, 0, 0.6) !important;
  border-radius: 5px;
  padding: 8px;
  margin-bottom: 12px;
  width: 242px;
  height: 55px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
} */

#canv {
  border: 1.5px dashed rgba(0, 0, 0, 0.6) !important;
  border-radius: 5px;
  padding: 8px;
  margin-bottom: 12px;
  width: 242px;
  height: 55px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  /* background: radial-gradient(circle, rgba(0, 0, 0, 0.6) 1px, transparent 1px);
  background-size: 10px 10px;
  background-position: center; */
}

#reload_href {
  position: fixed !important;
  right: 65px;
  bottom: 226px;
  z-index: 999;
  font-size: 5px;
  opacity: 0;
  width: 40px;
  height: 40px;
  background-color: #009035;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1280px) {
  #reload_href {
    bottom: 240px;
  }
}

.captacha .freshBtn {
  position: absolute;
  top: -7px;
  right: 36px;
  z-index: -1;
  cursor: pointer;
}

.freshBtn.rotate {
  animation: rotate 1s ease-in-out forwards;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* *************************** Resposive breakpoints ************************** */

/* 
@media (max-width:1440px){
   
}
@media (max-width:1280px){
   
}
@media (max-width:1024px){
   
}
@media (max-width:991px){
   
}
@media (max-width:767px){
   
} */
